import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilterMatchMode, SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { PaginatorComponent } from '../paginator/paginator.component';
import { ImportsModule } from './imports';
@Component({
  selector: 'rcp-grid',
  standalone: true,
  imports: [ImportsModule, CommonModule, PaginatorComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnChanges {
  @Input() gridData: any;
  @Input() headerColumns: any;
  @Input() first: number;
  @Input() rows: number;
  @Input() isRequiredStatus = false;
  @Input() statusIndex: number;
  @Input() passGridName: string;
  @Input() gridFilterAndSorting: boolean;
  @Input() gridSorting: boolean;
  @Input() gridFilter: boolean;
  @Input() loading: boolean;
  @Input() gridSortData: any;
  @Input() gridFilterData: any;
  @Output() headerGlobalFilter = new EventEmitter<any>();
  @Output() onGridDateClickEvent = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();
  @Output() sortChangeEvent = new EventEmitter<{
    field: string;
    order: number;
  }>();
  @ViewChild('treetable') treetable: Table | undefined;
  rowNode: any;
  filter = false;
  filterIndex: number;
  totalRecords: number;
  paginatorGridDatas: any;
  selected: any;
  highlighted: any;
  dataNotFound = false;
  dataNotFoundLabel = 'Data Not Found';
  constructor(
    private readonly cdf: ChangeDetectorRef,
    private readonly router: Router
  ) {}
  ngOnChanges() {
    this.handleParentEvent(this.first, this.rows);
  }
  // Header global filter function
  applyFilterGlobal(event: any, stringVal: any) {
    const treetable = this.treetable;
    this.headerGlobalFilter.emit({ event, stringVal, treetable });
    this.treetable.filterGlobal(
      (event.target as HTMLInputElement).value,
      stringVal
    );
  }
  onSort(event: SortEvent) {
    const field = event.field;
    const order = event.order;
    this.sortChangeEvent.emit({ field, order });
  }
  // Display inputbox into header section when click on filter icon.
  applyFilter(event: any, index: number) {
    if (this.filterIndex === index && this.filter) {
      this.filter = false;
      this.treetable.reset(); // This function is used for get the data
      this.treetable.filterGlobal('', FilterMatchMode.CONTAINS); // This function is used load the table
    } else {
      this.treetable.reset(); // This function is used for get the data
      this.treetable.filterGlobal('', FilterMatchMode.CONTAINS); // This function is used load the table
      this.filter = true;
      this.filterIndex = index;
    }
  }
  // Handle paginator event from parent component
  handleParentEvent(first?: any, rows?: any) {
    this.dataNotFound = false;
    const start = first;
    const end = rows;
    this.rows = rows;
    if (this.gridData.length !== 0) {
      this.paginatorGridDatas = this.gridData.slice(start, end);
      this.dataNotFound = false;
      this.cdf.detectChanges();
    } else {
      this.paginatorGridDatas = [];
      if (this.gridData.length === 0 && !this.loading) {
        this.dataNotFound = true;
        this.cdf.detectChanges();
      }
      this.cdf.detectChanges();
    }
  }
  // Empty paginatorGridDatas
  emptyGridOnReset() {
    this.paginatorGridDatas = [];
  }
  //This function is for date click event
  onNodeClick(event: any, index: any) {
    if (
      !isNaN(Date.parse(event?.target?.innerText)) &&
      index === 1 &&
      this.passGridName === 'sla-main-page'
    ) {
      this.onGridDateClickEvent.emit(event?.target?.innerText);
    }
    if (
      event?.target?.innerText &&
      this.router.url === '/outbound/on-time-ship-sla/list' &&
      this.passGridName === 'sla-list-view' &&
      index === 0
    ) {
      const client = sessionStorage.getItem('companyname');
      const eventData = {
        orderID: event?.target?.innerText,
        companyName: client,
      };
      const urlPath = `/outbound/order-details/info/${eventData.orderID}_${eventData.companyName}`;
      this.router.navigate([urlPath], {
        state: { eventData },
      });
    }
    //function for client sku click
    if (this.passGridName === 'inventory-list' && index === 0) {
      this.onRowClick.emit(event?.target?.innerText);
    }
    if (this.passGridName === 'outbound-metrics' && index === 0) {
      this.onRowClick.emit(event?.target?.innerText);
    }
  }
  // Add dynamic class for grid data
  getClassNameforData(date: any, index: number) {
    if (
      !isNaN(Date.parse(date)) &&
      index === 1 &&
      this.passGridName === 'sla-main-page'
    ) {
      return 'grid-text-underline';
    } else if (this.passGridName === 'inventory-list' && index === 0) {
      return 'grid-text-underline';
    } else {
      return 'grid-column-text';
    }
  }
  // Convert status string to number
  convertStrToNum(value: string) {
    return Number(value?.replace('%', ''));
  }
}
